import { template as template_3725002b794c49ff8678d50062ba572b } from "@ember/template-compiler";
import { t } from 'ember-intl';
import TooltipWithIcon from '../../ui/tooltip-with-icon';
export default template_3725002b794c49ff8678d50062ba572b(`
  <span class="evolution-header">

    {{t "pages.campaign-results.table.column.evolution"}}

    <TooltipWithIcon
      @iconName="help"
      @content={{@tooltipContent}}
      @ariaHiddenIcon={{true}}
      class="tooltip-with-icon-small"
    />
  </span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
