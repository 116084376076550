import { template as template_d645fa858e8340e499b0a63f7e70c5ae } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import { t } from 'ember-intl';
import ActionBar from '../ui/action-bar';
export default template_d645fa858e8340e499b0a63f7e70c5ae(`
  <ActionBar>
    <:information>
      {{t "pages.organization-participants.action-bar.information" count=@count}}
    </:information>
    <:actions>
      <PixButton @triggerAction={{@openDeletionModal}} type="button" @variant="error">
        {{t "pages.organization-participants.action-bar.delete-button"}}
      </PixButton>
    </:actions>
  </ActionBar>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
