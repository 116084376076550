import { template as template_4916d619f7994d679623d0f376497960 } from "@ember/template-compiler";
import PixNotificationAlert from '@1024pix/pix-ui/components/pix-notification-alert';
import { t } from 'ember-intl';
import { gt } from 'ember-truth-helpers';
export default template_4916d619f7994d679623d0f376497960(`
  {{#if (gt @occupied @total)}}
    <PixNotificationAlert @type="error" @withIcon={{true}}>
      {{t "banners.over-capacity.message"}}
    </PixNotificationAlert>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
