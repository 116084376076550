import { template as template_7fb0041c6d444658b004672b26ab03e8 } from "@ember/template-compiler";
import { htmlSafe } from '@ember/template';
import showdown from 'showdown';
import xss from 'xss';
const xssWhitelist = {
    ...xss.whiteList,
    style: [],
    span: [
        'style'
    ],
    th: [
        'style'
    ],
    td: [
        'style'
    ],
    tr: [
        'style'
    ],
    table: [
        'style'
    ],
    a: [
        'href',
        'rel',
        'target',
        'title'
    ]
};
const showdownOptions = {
    openLinksInNewWindow: true,
    strikethrough: true
};
export function toHtml(markdown) {
    const converter = new showdown.Converter(showdownOptions);
    const unsafeHtml = converter.makeHtml(markdown);
    const html = xss(unsafeHtml, {
        whiteList: xssWhitelist
    });
    return htmlSafe(html);
}
export default template_7fb0041c6d444658b004672b26ab03e8(`
  <div class={{@class}} ...attributes>
    {{toHtml @markdown}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
