import { template as template_731232e034614e75b344d41c455ae63b } from "@ember/template-compiler";
import Certification from './certification';
import LanguageAvailability from './language-availability';
import Scommunication from './sco-communication';
import Survey from './survey';
export default template_731232e034614e75b344d41c455ae63b(`
  <div class="top-banners">
    <Certification />
    <Scommunication />
    <LanguageAvailability />
    <Survey />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
