import { template as template_ac76693ca49e4ecc8b10d6414697f5ee } from "@ember/template-compiler";
import PixBannerAlert from '@1024pix/pix-ui/components/pix-banner-alert';
import textWithMultipleLang from '../../helpers/text-with-multiple-lang.js';
export default template_ac76693ca49e4ecc8b10d6414697f5ee(`
  {{#each @banners as |banner|}}
    <PixBannerAlert @type={{banner.severity}}>
      {{textWithMultipleLang banner.message}}
    </PixBannerAlert>
  {{/each}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
