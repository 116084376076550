import { template as template_bd19ea3d6d874b4395d14aafb09ef263 } from "@ember/template-compiler";
import PixTooltip from '@1024pix/pix-ui/components/pix-tooltip';
export default template_bd19ea3d6d874b4395d14aafb09ef263(`
  {{#each @badges as |badge|}}
    <PixTooltip @id="badge-tooltip-{{badge.id}}" @position="bottom" @isInline={{true}}>
      <:triggerElement>
        <img
          src={{badge.imageUrl}}
          alt={{badge.altMessage}}
          tabindex="0"
          aria-describedby="badge-tooltip-{{badge.id}}"
        />
      </:triggerElement>
      <:tooltip>
        {{badge.title}}
      </:tooltip>
    </PixTooltip>
  {{/each}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
